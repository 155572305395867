const initialState = {
  open: false,
};

function sidebarReducer(action) {
  switch (action?.type) {
    case "OPEN_SIDEBAR":
      return {
        open: true,
      };
    case "CLOSE_SIDEBAR":
      return {
        open: false,
      };
    default:
      return initialState;
  }
}

export default sidebarReducer;
