/* eslint-disable import/prefer-default-export */
import { Navigate } from "react-router-dom";
const AnonymousRoute = ({ children }) => {
  const user = localStorage.getItem("user");

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
export default AnonymousRoute;
