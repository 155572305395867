/* eslint-disable */
import authReducer from "./auth.reducer";
import sidebarReducer from "./sidebar.reducer";
import { combineReducers } from "redux";
import modalReducer from "../modal/modal.reducer";
import suggestReducer from "../suggest/suggest.reducer";

export default combineReducers({
  auth: authReducer,
  modal: modalReducer,
  suggest: suggestReducer,
  sideBar: sidebarReducer,
});
